@import './../../../../scss/theme-bootstrap';

.sitewide-footer-formatter {
  position: relative;
  clear: both;
  margin: 0 auto;
  text-align: $ldirection;
  color: $color-white;
  // ADA style
  a,
  button,
  input,
  select,
  [tabindex='0'] {
    &:focus {
      outline: none;
      .ada-enabled &,
      .no-js & {
        outline: 1px dotted $color-light-blue;
      }
    }
  }
  &__body,
  &__bottom,
  &__title {
    background-color: $color-black;
    @include breakpoint($landscape-up) {
      padding: 40px 30px 0;
    }
  }
  &__body {
    display: block;
    padding-top: 15px;
    @include breakpoint($landscape-up) {
      display: flex;
      padding: 40px 30px 0;
    }
  }
  &__content {
    display: block;
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      padding: 0 80px;
      display: flex;
    }
    &:nth-child(1) {
      flex: 0 1 30%;
      flex-direction: column;
      width: 100%;
      @include breakpoint($landscape-up) {
        padding: 0 60px;
      }
    }
    &:nth-child(2) {
      flex: 0 1 40%;
      padding: 0 30px;
      @include breakpoint($landscape-up) {
        padding: 0 40px;
      }
    }
    &:nth-child(3) {
      justify-content: center;
      align-items: center;
      flex: 0 1 30%;
    }
  }
  &__title {
    padding-top: 35px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
    p {
      margin: 0;
    }
  }
  &__copyright {
    width: 100%;
    padding: 0 30px 45px;
    font-size: 12px;
    @include breakpoint($landscape-up) {
      display: flex;
      justify-content: center;
    }
    p {
      margin: 0;
    }
  }
  &__links {
    flex: 1;
    padding-top: 15px;
    &:first-child {
      padding-top: 0;
    }
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__accessibility {
    display: none;
    text-align: center;
    width: calc(100% - 78px);
    margin: 10px;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__mobile-accessibility {
    display: block;
    padding-top: 15px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__top {
    width: 100%;
  }
  &__social-network {
    display: flex;
    justify-content: center;
    @include breakpoint($landscape-up) {
      justify-content: end;
    }
    a {
      text-decoration: none;
    }
  }
  &__country-chooser {
    margin: 10px;
    @include breakpoint($landscape-up) {
      width: calc(100% - 78px);
    }
    .country-chooser {
      margin: 0;
    }
    .country-chooser,
    .country-select__menu,
    .country-select__selectbox {
      width: 100%;
    }
    .language-select {
      text-align: center;
      padding-top: 15px;
    }
    .country-select__selectbox {
      text-align-last: center;
      height: 45px;
      border: 1px solid $color-white;
      font-family: $font--avenir-roman;
      font-size: 14px;
      background-position: 95% 50%;
      background-image: url('/media/images/global/country_select_arrow.gif');
      background-repeat: no-repeat;
      appearance: none;
      text-transform: none;
      background-size: 25px;
      @include breakpoint($landscape-up) {
        background: none;
      }
      .selectBox-label {
        width: 100%;
      }
    }
  }
  &__cruelty-free {
    text-align: $ldirection;
    padding: 15px 0;
    width: 40%;
    @include breakpoint($landscape-up) {
      text-align: center;
      padding: 0;
      width: auto;
    }
  }
}
